

































































import { defineComponent } from "@vue/composition-api";
import validator from "validator";

export default defineComponent({
  props: {
    value: {
      type: Array,
      required: true,
      default: () => [
        {
          firstName: "",
          lastName: "",
          email: "",
          address: "",
          affiliation: "",
        },
      ],
    },
  },
  setup(props, { root }) {
    const addAuthor = () => {
      props.value.push({
        firstName: "",
        lastName: "",
        email: "",
        address: "",
        affiliation: "",
      });
    };

    const removeAuthor = (index: number) => {
      props.value.splice(index, 1);
    };

    const rules = {
      required: [(v: string) => !!v || `${root.$t("layout.misc.required")}`],
      email: [
        (v: string) =>
          !v ||
          validator.isEmail(v) ||
          root.$tc("layout.misc.validEmailAddress"),
      ],
    };
    return { addAuthor, removeAuthor, rules };
  },
});
